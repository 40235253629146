import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row } from 'reactstrap';
import NewsCard from "./NewsCard";
import { Tabs } from 'antd';
import './NewsCard.css';

const { TabPane } = Tabs;

const NewsList = () => {
    const [newsData, setNewsData] = useState([]);
    const [votes, setVotes] = useState({});

    useEffect(() => {
        axios
            .get("https://web-production-0710.up.railway.app/headlines")
            .then((response) => {
                setNewsData(response.data);
                // Initialize votes state
                const initialVotes = {};
                response.data.forEach(category => {
                    category.data.forEach(newsItem => {
                        initialVotes[newsItem.Title] = { count: 0, userVote: 0 }; // initialize with count and userVote
                    });
                });
                setVotes(initialVotes);
            })
            .catch((error) => {
                console.error("Error fetching the news:", error);
            });
    }, []);

    const handleVote = (title, voteChange) => {
        setVotes(prevVotes => {
            const currentVote = prevVotes[title].userVote;
            let newVoteCount = prevVotes[title].count;

            if (currentVote === voteChange) {
                // If user clicks the same vote button again, remove their vote
                newVoteCount -= voteChange;
                voteChange = 0;
            } else {
                // Adjust the vote count based on the current and new vote
                newVoteCount += voteChange - currentVote;
            }

            return {
                ...prevVotes,
                [title]: { count: newVoteCount, userVote: voteChange }
            };
        });
    };

    const allNewsItems = newsData.flatMap(category => category.data)
        .filter(newsItem => newsItem.ImageURL); // Filter out items without an ImageURL

    return (
        <div className="news-cards">
            <Container>
                <Tabs defaultActiveKey="0">
                    <TabPane>
                        {allNewsItems.map((newsItem, idx) => (
                            idx % 3 === 0 &&
                            <div key={idx}>
                                <Row>
                                    {allNewsItems.slice(idx, idx + 3).map((item, cardIdx) => {
                                        const voteData = votes[item.Title] || { count: 0, userVote: 0 };
                                        return (
                                            <NewsCard
                                                key={cardIdx}
                                                newsItem={item}
                                                votes={voteData.count}
                                                userVote={voteData.userVote}
                                                handleVote={handleVote}
                                            />
                                        );
                                    })}
                                </Row>
                            </div>
                        ))}
                    </TabPane>
                </Tabs>
            </Container>
        </div>
    );
};

export default NewsList;
