import React from "react";
import { Card, Col, Row, Container } from 'reactstrap';
import { Form, Input, Button } from "antd";
import axios from './axios'; // Correct import path
import { useNavigate } from 'react-router-dom';
import "./Login.css";

const Signup = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await axios.post('/signup', values);
      console.log('Success:', response.data);
      navigate('/login');
    } catch (error) {
      console.log('Failed:', error.response.data);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="6" lg="6" className="mb-5">
          <Card className="login-form">
            <h2 className="heading">Create Account</h2>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Full Name"
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: "Please input your fullname!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password autoComplete="off" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Create Account
                </Button>
                <div className="login-info-text">
                  Already have an account? <span onClick={handleLoginClick}>Login</span>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
