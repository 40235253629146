import React from "react";
import { Card, Col, Row, Container } from 'reactstrap';
import { Form, Input, Button, Checkbox } from "antd";
import axios from './axios'; // Correct import path
import { useNavigate } from 'react-router-dom';
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await axios.post('/login', values);
      console.log('Success:', response.data);
      localStorage.setItem('token', response.data.token);
      navigate('/home');
    } catch (error) {
      console.log('Failed:', error.response.data);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="6" lg="6" className="mb-5">
          <Card className="login-form">
            <h2 className="heading">Login</h2>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password autoComplete="off" />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <div className="login-info-text">
                  Don't have an account? <span onClick={handleSignupClick}>Create new</span>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
