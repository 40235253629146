import React from 'react';
import { Layout, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const { Footer } = Layout;

const AppFooter = () => {
    return (
        <Footer className="app-footer">
            <div className='footer-content'>
                <Row>
                    <Col sm={6} md={6} lg={4}>
                        <h3>Follow Us</h3>
                        <div className="social-icons">
                            <a className='twitter' href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} size="2x" />
                            </a>
                            <a className='facebook' href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                            <a className='insta' href="https://www.instagram.com/m/briefbeat" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2x" />
                            </a>
                            <a className='linkedin' href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} size="2x" />
                            </a>
                        </div>
                    </Col>
                    <Col sm={6} md={6} lg={4}>
                        <h3>Contact Us</h3>
                        <p>Email: </p>
                        <p>Briefbeat.ai@gmail.com </p>
                        {/* <p>Phone: +123 456 7890 </p> */}
                    </Col>
                </Row>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} BriefBeat Inc. All rights reserved</p>
            </div>
        </Footer>
    );
};

export default AppFooter;
