import React, { useEffect, useState } from "react";
import { Card, Col, Row, Container } from 'reactstrap';
import { Form, Input, Button, Checkbox, Modal, Typography } from "antd";
import axios from './axios'; // Correct import path
import { useNavigate } from 'react-router-dom';
import "./CollectEmail.css";

const { Title, Paragraph } = Typography;

const preferencesOptions = [
  "Technology",
  "Business",
  "Health",
  "Entertainment",
  "Sports",
  "Science"
];

const CollectEmail = () => {
  const [isPreferencesModalVisible, setIsPreferencesModalVisible] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
    const isRegistered = localStorage.getItem('isRegistered');
    if (isRegistered) {
      navigate('/newslist');
    }
  }, [navigate]);

  const onFinish = async (values) => {
    console.log('Values to be sent:', values);  // Debugging line
    try {
      const response = await axios.post('/collect-email', values);
      console.log('Success:', response.data); // Debugging line
      localStorage.setItem('isRegistered', 'true');
      localStorage.setItem('userEmail', values.email); // Store email for further use
      setIsPreferencesModalVisible(true); // Show preferences modal
    } catch (error) {
      if (error.response && error.response.status === 409) {
        console.log('Failed: Email already exists'); // Debugging line
        localStorage.setItem('isRegistered', 'true');
        localStorage.setItem('userEmail', values.email); // Store email for further use
        setIsPreferencesModalVisible(true); // Show preferences modal
      } else {
        console.log('Failed:', error.response ? error.response.data : error.message); // Debugging line
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handlePreferencesOk = async () => {
    const email = localStorage.getItem('userEmail');
    if (email && selectedPreferences.length > 0) {
      try {
        await axios.post('/save-preferences', { email, preferences: selectedPreferences });
        console.log('Preferences saved successfully');
      } catch (error) {
        console.log('Error saving preferences:', error);
      }
    }
    setIsPreferencesModalVisible(false);
    navigate('/newslist');
  };

  const handlePreferencesSkip = () => {
    setIsPreferencesModalVisible(false);
    navigate('/newslist');
  };

  const onPreferencesChange = (checkedValues) => {
    setSelectedPreferences(checkedValues);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="6" lg="6" className="mb-5">
          <Card className="collect-email-form">
            <h2 className="heading">Join Our Mailing List</h2>
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    type: 'email',
                    message: "Please enter a valid email!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <Button type="link" onClick={() => navigate('/newslist')}>
              Already on our list? Continue to BriefBeat
            </Button>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Select Your News Preferences"
        visible={isPreferencesModalVisible}
        onOk={handlePreferencesOk}
        footer={[
          <Button key="skip" onClick={handlePreferencesSkip}>
            Skip
          </Button>,
          <Button key="submit" type="primary" onClick={handlePreferencesOk}>
            OK
          </Button>,
        ]}
        width={600} // Increase the width of the modal
      >
        <Title level={4}>Help us curate news just for you!</Title>
        <Paragraph>Please select your preferences to get personalized news updates.</Paragraph>
        <Checkbox.Group options={preferencesOptions} onChange={onPreferencesChange} />
      </Modal>
    </Container>
  );
};

export default CollectEmail;
