import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NewsList from './NewsList';
import Login from './Login';
import Signup from './Signup';
import Header from './Header';
import Footer from './Footer';
import CollectEmail from './CollectEmail';
import { inject } from '@vercel/analytics';
 
inject();

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/mail-list" />} /> {/* Redirect root to mail-list */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/mail-list" element={<CollectEmail />} /> {/* New route */}
        <Route path="/newslist" element={<NewsList />} /> {/* Ensure this route exists */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
