import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, CardText, Col } from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock as faClockRegular, faShareSquare as faShareAltSolid, faEye, faFlag } from '@fortawesome/free-regular-svg-icons';
import { faArrowUp, faArrowDown, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faReddit, faFacebook } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
import { Modal, Collapse, Dropdown, Menu, Button, Input } from 'antd';
import axios from './axios';
import './NewsCard.css'; // Ensure your CSS isn't affecting list styles
import loader from './loader.gif';
import verified from './imgs/verified.png';

const { Panel } = Collapse;
const { TextArea } = Input;

const NewsCard = ({ newsItem, votes, handleVote }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewCount, setViewCount] = useState(0);
    const [isReportModalVisible, setIsReportModalVisible] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [userVote, setUserVote] = useState(0);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        const fetchVote = async () => {
            try {
                const response = await axios.get('/get-vote', {
                    params: { title: newsItem.Title }
                });
                setUserVote(response.data.userVote);
            } catch (error) {
                console.log('Error fetching vote:', error);
            }
        };

        const fetchViews = async () => {
            try {
                const response = await axios.get('/get-view', {
                    params: { title: newsItem.Title }
                });
                setViewCount(response.data.count);
            } catch (error) {
                console.log('Error fetching views:', error);
            }
        };

        const fetchComments = async () => {
            try {
                const response = await axios.get('/get-comments', {
                    params: { title: newsItem.Title }
                });
                setComments(response.data);
            } catch (error) {
                console.log('Error fetching comments:', error);
            }
        };

        fetchVote();
        fetchViews();
        fetchComments();
    }, [newsItem.Title]);

    const PLATFORM_URL = 'https://www.briefbeat.ai';

    const handleUpVote = async (e) => {
        e.preventDefault();
        const newVote = userVote === 1 ? 0 : 1;
        setUserVote(newVote);
        try {
            await axios.post('/vote', {
                title: newsItem.Title,
                userVote: newVote
            });
        } catch (error) {
            console.log('Error updating vote:', error);
        }
    };

    const handleDownVote = async (e) => {
        e.preventDefault();
        const newVote = userVote === -1 ? 0 : -1;
        setUserVote(newVote);
        try {
            await axios.post('/vote', {
                title: newsItem.Title,
                userVote: newVote
            });
        } catch (error) {
            console.log('Error updating vote:', error);
        }
    };

    const formatPostedTime = (postedTime) => {
        const now = moment();
        const postTime = moment(postedTime);
        const duration = moment.duration(now.diff(postTime));
        let hours = duration.asHours();

        if (hours < 0) {
            hours = 1;
        }

        if (hours < 24) {
            return `${Math.floor(hours)} hours ago`;
        } else {
            return postTime.format('DD/MM/YYYY, HH:mm:ss');
        }
    };

    const showModal = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
        setViewCount(viewCount + 1);
        axios.post('/increment-view', { title: newsItem.Title });
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleShare = (platform) => {
        let shareUrl = '';
        const text = encodeURIComponent(`${newsItem.Title} via @briefbeat`);
        const briefBeatURL = encodeURIComponent('https://www.briefbeat.ai');

        switch (platform) {
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?text=${text} ${briefBeatURL}`;
                break;
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${briefBeatURL}&quote=${text}`;
                break;
            case 'reddit':
                shareUrl = `https://www.reddit.com/submit?title=${text}&url=${briefBeatURL}`;
                break;
            default:
                return;
        }
        window.open(shareUrl, '_blank');
    };

    const shareMenu = (
        <Menu>
            <Menu.Item className='twitter' key="twitter" onClick={() => handleShare('twitter')}>
                <FontAwesomeIcon icon={faTwitter} size="2x" />
                <span>Twitter</span>
            </Menu.Item>
            <Menu.Item className='facebook' key="facebook" onClick={() => handleShare('facebook')}>
                <FontAwesomeIcon icon={faFacebook} size="2x" />
                <span>Facebook</span>
            </Menu.Item>
            <Menu.Item className='reddit' key="reddit" onClick={() => handleShare('reddit')}>
                <FontAwesomeIcon icon={faReddit} size="2x" />
                <span>Reddit</span>
            </Menu.Item>
        </Menu>
    );

    const showReportModal = () => {
        setIsReportModalVisible(true);
    };

    const handleReportOk = () => {
        console.log('Feedback submitted:', feedback);
        setIsReportModalVisible(false);
        setFeedback('');
    };

    const handleReportCancel = () => {
        setIsReportModalVisible(false);
        setFeedback('');
    };

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const addComment = async () => {
        if (newComment.trim() === '') return;
        try {
            await axios.post('/add-comment', { title: newsItem.Title, comment: newComment });
            setComments([{ comment: newComment, name: 'Anonymous', timestamp: new Date() }, ...comments]);
            setNewComment('');
        } catch (error) {
            console.log('Error adding comment:', error);
        }
    };

    const handleSpeech = () => {
        const utterance = new SpeechSynthesisUtterance(newsItem.Detailed_Summary.join('\n'));
        utterance.voice = window.speechSynthesis.getVoices().find(voice => voice.lang === 'en-US');
        window.speechSynthesis.speak(utterance);
    };

    if (!newsItem.ImageURL) {
        return null; // Don't render the card if there is no image URL
    }

    const renderDetailedSummary = () => {
        if (Array.isArray(newsItem.Detailed_Summary)) {
            return (
                <ul style={{ paddingLeft: '20px' }}> {/* Ensure padding is set */}
                    {newsItem.Detailed_Summary.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            );
        }
        return <p>No detailed summary available.</p>;
    };

    return (
        <Col sm="12" md="6" lg="4" className="mb-5">
            <Card className="news-card">
                <LazyLoadImage
                    src={newsItem.ImageURL}
                    alt={newsItem.Title}
                    effect="blur"
                    className="card-img-top"
                    placeholderSrc={loader}
                    onClick={showModal}
                    style={{ cursor: 'pointer' }}
                />

                <CardBody>
                    <CardTitle tag="h3">{newsItem.Title}</CardTitle>
                    <div className='mb-2'>
                        <CardText>
                            <span className='pe-2'>{truncateText(newsItem.Description, 10)}</span>
                        </CardText>
                        <span className='read-more'>
                            <a href='./#' color="link" onClick={showModal}>Read more</a>
                        </span>
                        <Button type="link" onClick={handleSpeech}>
                            <FontAwesomeIcon icon={faVolumeUp} /> Hear Summary
                        </Button>
                    </div>
                    <div className='card-sources'>
                        <a href={newsItem.URL}>
                            <span className='me-2'><img src={verified} alt='Verified Icon' /></span>{newsItem.Source}
                        </a>

                        <CardText>
                            <span className='me-2'><FontAwesomeIcon icon={faClockRegular} /></span>{formatPostedTime(newsItem.Posted_Time)}
                        </CardText>
                    </div>

                    <div className='card-bottom'>
                        <div className='up-down-vote'>
                            <a href='./#' className={`upVote ${userVote === 1 ? 'active' : ''}`} onClick={handleUpVote}>
                                <FontAwesomeIcon icon={faArrowUp} />
                            </a>
                            <span className='ms-2 me-2'>{votes + (userVote === 1 ? 1 : userVote === -1 ? -1 : 0)}</span>
                            <a href='./#' className={`downVote ${userVote === -1 ? 'active' : ''}`} onClick={handleDownVote}>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </a>
                        </div>

                        <div className='view-count'>
                            <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
                            {viewCount} views
                        </div>

                        <Button type="link" onClick={showReportModal} className='ms-3 report-button'>
                            <FontAwesomeIcon icon={faFlag} style={{ marginRight: '5px' }} /> Report
                        </Button>
                    </div>
                </CardBody>
            </Card>

            <Modal centered title={newsItem.Title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <h4>News Summary:</h4>
                {renderDetailedSummary()}

                <h4>FAQ:</h4>
                <Collapse defaultActiveKey={newsItem.FAQ.map((_, index) => index.toString())}>
                    {newsItem.FAQ.map((faq, index) => (
                        <Panel header={<span><strong>Q:</strong> {faq.Question}</span>} key={index}>
                            <p><strong>A:</strong> {faq.Answer}</p>
                        </Panel>
                    ))}
                </Collapse>

                <h4 style={{ marginTop: '20px' }}>Join the Conversation with BriefBeat:</h4>
                <div style={{ maxHeight: '200px', overflowY: 'auto', marginBottom: '20px' }}>
                    {comments.map((comment, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                            <strong>{comment.name}:</strong> {comment.comment}
                            <br />
                            <small>{moment(comment.timestamp).fromNow()}</small>
                        </div>
                    ))}
                </div>

                <TextArea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    rows={2}
                    placeholder="Add your comment"
                />
                <Button type="primary" onClick={addComment} style={{ marginTop: '10px' }}>
                    Submit
                </Button>

                <Dropdown overlay={shareMenu} trigger={['click']}>
                    <a href='./#' className='share-icon' onClick={(e) => e.preventDefault()}>
                        <FontAwesomeIcon icon={faShareAltSolid} style={{ cursor: 'pointer' }} />
                        <span className='ms-1'>Share</span>
                    </a>
                </Dropdown>
            </Modal>

            <Modal title="Report News" visible={isReportModalVisible} onOk={handleReportOk} onCancel={handleReportCancel}>
                <h4>Why do you think this news is wrong?</h4>
                <TextArea value={feedback} onChange={handleFeedbackChange} rows={4} />
            </Modal>
        </Col>
    );
};

const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

export default NewsCard;
